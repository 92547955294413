import { useMemo } from "react";
import { useIntl } from "react-intl";
import { getDateTimeValues } from "../../../common/dateHelpers";
import messages from "./messages";
import commonMessages from "../../../common/messages";

const useTranslations = (params: {
  scheduledAt: Date;
  originalAppointmentAt?: Date;
  additionalAppointmentAt?: Date;
}) => {
  const { scheduledAt, originalAppointmentAt, additionalAppointmentAt } =
    params;

  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      edit: formatMessage(commonMessages.edit),
      labelDate: formatMessage(messages.labelDate),
      labelTime: formatMessage(messages.labelTime),
      labelCenter: formatMessage(messages.labelCenter),
      labelCustomer: formatMessage(messages.labelCustomer),
      tooltipRequiresContactingPatient: formatMessage(
        messages.tooltipRequiresContactingPatient,
      ),
      helperScheduledAt: formatMessage(
        messages.helperScheduledAt,
        getDateTimeValues(scheduledAt),
      ),
      originalAppointmentAt: originalAppointmentAt
        ? formatMessage(
            messages.originalAppointmentAt,
            getDateTimeValues(originalAppointmentAt),
          )
        : "",
      additionalAppointmentAt: additionalAppointmentAt
        ? formatMessage(
            messages.originalAppointmentAt,
            getDateTimeValues(additionalAppointmentAt),
          )
        : "",
      labelCenterServices: formatMessage(messages.labelCenterServices),
      labelAddOns: formatMessage(messages.labelAddOns),
      statusUnknown: formatMessage(messages.statusUnknown),
      statusCheckout: formatMessage(messages.statusCheckout),
      statusIncomplete: formatMessage(messages.statusIncomplete),
      statusAbandoned: formatMessage(messages.statusAbandoned),
      statusConfirmed: formatMessage(messages.statusConfirmed),
      statusCanceled: formatMessage(messages.statusCanceled),
      statusStudiesRead: formatMessage(messages.statusStudiesRead),
      statusStudiesUnread: formatMessage(messages.statusStudiesUnread),
      statusReportsSent: formatMessage(messages.statusReportsSent),
      statusReportsError: formatMessage(messages.statusReportsError),
      statusNoShow: formatMessage(messages.statusNoShow),
      statusIntakeComplete: formatMessage(messages.statusIntakeComplete),
      statusCheckedIn: formatMessage(messages.statusCheckedIn),
      tooltipStat: formatMessage(messages.tooltipStat),
      tooltipPriority: formatMessage(messages.tooltipPriority),
      additionalViews: formatMessage(messages.additionalViews),
      tooltipUnexpectedFindings: formatMessage(
        messages.tooltipUnexpectedFindings,
      ),
      tooltipReceiptConfirmed: formatMessage(messages.tooltipReceiptConfirmed),
      additionalViewsAppointment: formatMessage(
        messages.additionalViewsAppointment,
      ),
      originalAppointment: formatMessage(messages.originalAppointment),
      downloadScreeningForm: formatMessage(messages.downloadScreeningForm),
    };
  }, [
    additionalAppointmentAt,
    formatMessage,
    originalAppointmentAt,
    scheduledAt,
  ]);

  return translations;
};

export default useTranslations;
