import React, { FC } from "react";
import { useField } from "formik";
import Select, { SelectProps } from "../Select";

type FormikSelectProps = { name: string } & Omit<
  SelectProps,
  | "type"
  | "value"
  | "multiline"
  | "onChange"
  | "onBlur"
  | "error"
  | "helperText"
>;

const FormikSelect: FC<FormikSelectProps> = ({ name, children, ...props }) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  return (
    <Select {...props} {...field} error={touched && Boolean(error)}>
      {children}
    </Select>
  );
};

export default FormikSelect;
