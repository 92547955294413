import { useCallback, useEffect, useMemo, useState } from "react";
import getPatientData from "./getPatientData";
import { Patient } from "./types";
import useTranslations from "./useTranslations";
import { createSupabaseClient } from "../../../hooks/useSupabase";

const usePatientData = (patientMrn?: string) => {
  const { translations } = useTranslations();

  const supabase = useMemo(() => createSupabaseClient(), []);

  const { defaultError, errorPatientNotFound } = translations;

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<Patient | null>(null);

  const [error, setError] = useState<string | null>(null);

  const fetchPatient = useCallback(async () => {
    if (!patientMrn) return;

    try {
      setIsLoading(true);
      let patient: Patient | null = null;

      try {
        patient = await getPatientData({ mrn: patientMrn, supabase });
      } catch (err) {
        throw new Error(defaultError);
      }

      if (!patient) {
        throw new Error(errorPatientNotFound);
      }

      setData(patient);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [defaultError, errorPatientNotFound, patientMrn, supabase]);

  useEffect(() => {
    fetchPatient();
  }, [fetchPatient]);

  return { isLoading, error, data, refetch: fetchPatient };
};

export default usePatientData;
