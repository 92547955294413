import { Database } from "@Shape-Digital/kudzu-data/lib/types/supabase";
import { createClient } from "@supabase/supabase-js";
import { SUPABASE_TOKEN } from "./constants";

const { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY } = process.env;

const createSupabaseClient = (accessToken: string | null) => {
  if (!REACT_APP_SUPABASE_URL) {
    throw new Error("Please provide REACT_APP_SUPABASE_URL in .env file");
  }

  if (!REACT_APP_SUPABASE_KEY) {
    throw new Error("Please provide REACT_APP_SUPABASE_KEY in .env file");
  }

  const client = createClient<Database>(
    REACT_APP_SUPABASE_URL,
    REACT_APP_SUPABASE_KEY,
    {
      global: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      realtime: {
        accessToken: async () => {
          return (
            localStorage.getItem(SUPABASE_TOKEN) || REACT_APP_SUPABASE_KEY || ""
          );
        },
      },
    },
  );

  return client;
};

const supabaseClient = createSupabaseClient(
  localStorage.getItem(SUPABASE_TOKEN),
);

export default supabaseClient;
