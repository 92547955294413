export const appointmentRequest = `
id,
status,
time_slot_id,
created_at,
has_priority,
is_stat,
is_contacting_patient_required,
is_additional_views,
is_additional_views_required,
total_cost_amount,
discount_amount,
amount_paid,
appointment_offerings (
  id,
  acc,
  side,
  status,
  pacs_url,
  total_cost_amount,
  modality_name,
  has_unexpected_findings,
  is_receipt_confirmed,
  is_additional_views_required,
  additional_view_duration_minutes,
  center_offering: center_offering_id (
    id,
    name,
    duration_minutes
  ),
  appointment_add_ons (
    id,
    center_add_on: center_add_on_id (
      id,
      name,
      type
    )
  )
),
appointment_packages (
  id,
  center_package: center_package_id (
    id,
    name
  ),
  appointment_add_ons (
    id,
    center_add_on: center_add_on_id (
      id,
      name,
      type
    )
  ),
  appointment_offerings (
    id,
    acc,
    side,
    status,
    pacs_url,
    total_cost_amount,
    modality_name,
    has_unexpected_findings,
    is_receipt_confirmed,
    is_additional_views_required,
    additional_view_duration_minutes,
    center_offering: center_offering_id (
      id,
      name,
      duration_minutes
    )
  )
),
appointment_add_ons (
  id,
  center_add_on: center_add_on_id (
    id,
    name,
    type
  )
),
center:center_id (
  id,
  name,
  timezone,
  region,
  center_operating_hours (
    day_of_week_number,
    from_minutes_at,
    to_minutes_at
  ),
  center_configs (
    appointment_checkout_duration_seconds,
    appointment_checkout_max_repeat_count,
    minimum_time_before_booking_seconds,
    default_offering_duration_minutes,
    default_currency_code,
    refund_canceling_appointment_min_hours,
    discount_amount
  ),
  center_time_slots (
    started_at,
    ended_at
  ),
  center_payment_provider_readers (
    center_payment_provider_id (
      payment_provider_id
    ),
    payment_provider_reader_id,
    payment_provider_reader_label
  ),
  center_payment_methods (
    id,
    center_payment_provider: center_payment_provider_id(
      payment_provider: payment_provider_id (
        type
      )
    ),
    payment_method: payment_method_id (
      id,
      type
    )
  )
),
center_time_slot: time_slot_id (
  started_at,
  ended_at
),
appointment_notes (
  id,
  created_at,
  description,
  center_user: center_user_id (
    first_name,
    last_name
  ),
  appointment_offering: appointment_offering_id (
    id,
    modality_name
  )
),
appointment_technologist_notes (*),
appointment_files (
  id,
  url,
  name,
  created_at
),
appointment_patient_details (
  first_name,
  last_name,
  mobile_phone_number,
  email,
  date_birth,
  patient_id,
  patient: patient_id (
    mrn,
    race,
    sex,
    patient_forms (
      type,
      expiration_at
    )
  ),
  customer: customer_id (
    id,
    first_name,
    last_name
  )
),
appointment_patient_details_form (
  id,
  exam_reason,
  surgical_history,
  cancer_history,
  height_ft,
  height_in,
  weight_lbs,
  referring_physician: referring_physician_id (
    name,
    email,
    zip_code,
    phone_number
  )
),
appointment_customer_details (
  id,
  first_name,
  last_name,
  type,
  customer_id,
  customer: customer_id (
    id,
    payment_provider_customers (
      payment_provider_user_id
    )
  )
),
original_appointments:appointment_additional_views!appointment_additional_views_appointment_view_id_fkey (
  appointment_id (
    id,
    time_slot_id (
      started_at
    )
  )
),
additional_views_appointments:appointment_additional_views!appointment_additional_views_appointment_id_fkey (
  appointment_view_id (
    id,
    time_slot_id (
      started_at
    )
  )
),
appointment_forms (
  type,
  patient_signed_at,
  sensitive_items,
  center_user_signed_at,
  file_name
),
appointment_refunds (
  id,
  amount,
  created_at,
  customer_name,
  appointment_payment_id,
  payment_method_label,
  center_payment_method: center_payment_method_id (
    payment_method: payment_method_id (
      type
    )
  )
),
appointment_payments (
  id,
  transaction_id,
  amount,
  customer_name,
  confirmed_at,
  payment_method_label,
  center_payment_provider: center_payment_provider_id (
    payment_provider: payment_provider_id (
      type
    )
  ),
  center_payment_method: center_payment_method_id (
    payment_method: payment_method_id (
      type
    )
  )
),
appointment_reports (
  id,
  filename,
  status,
  has_unexpected_findings,
  is_receipt_confirmed
)
`;

export const MAX_BOOK_APPOINTMENT_YEARS = 2;

export const ROUND_BOOK_APPOINTMENT_START_TO_NEAREST_MINUTES = 30;
