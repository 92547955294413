import { useState } from "react";
import { AppointmentOffering } from "@Shape-Digital/kudzu-data/lib/types/actions";

import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";

import {
  AddOnsState,
  SelectedCenterService,
  SelectedOfferingSide,
} from "./types";

export const useSubmitHandler = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const editAppointmentServices = useFirebaseAppFunction(
    "editAppointmentServices",
  );

  const onSubmit = async ({
    appointmentId,
    selectedAddOns,
    selectedCenterServices,
  }: {
    appointmentId: string;
    selectedAddOns: AddOnsState;
    selectedCenterServices: (SelectedCenterService | null)[];
  }) => {
    const selectedAddonsIds = selectedAddOns.map((addOn) => addOn.id);
    const selectedOfferings: AppointmentOffering[] = selectedCenterServices
      .filter((option) => !!option && option.groupId === "single-offering")
      .map((option) => ({
        id: option?.value || "",
        side: (option?.side || null) as SelectedOfferingSide | null,
        selectedAddOns: option?.addOnIds || [],
      }));

    const selectedPackages = selectedCenterServices
      .filter((option) => !!option && option.groupId === "package")
      .map((option) => ({
        id: option?.value || "",
        selectedAddOns: option?.addOnIds || [],
      }));

    try {
      setIsLoading(true);
      await editAppointmentServices({
        appointmentId,
        addOnsIds: selectedAddonsIds,
        packages: selectedPackages,
        offerings: selectedOfferings,
      });
      onSuccess();
    } catch (error) {
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  return { onSubmit, isLoading };
};
