import React, { FC, useMemo, useState } from "react";

import { Appointment } from "../AppointmentPreviewCard/types";

import StatusDialog from "../../Unknown/StatusDialog";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";

import useActionButtons from "./useActionButtons";
import useTranslations from "./translations";
import AppointmentModifyServicesForm from "./form";
import AppointmentSummaryDetails from "./summary";
import useCenterServiceOptions from "./useCenterServiceOptions";
import SelectAddOnsForm from "./addOnsForm";
import { useSubmitHandler } from "./useSubmitHandler";

export type AppointmentModifyServicesDialogProps = {
  appointment: Appointment;
  isOpened: boolean;
  onClose: () => void;
  handleSuccess: () => void | Promise<void>;
};

const AppointmentModifyServicesDialog: FC<
  AppointmentModifyServicesDialogProps
> = ({ appointment, isOpened, handleSuccess, onClose }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { translations } = useTranslations();

  const { onSubmit, isLoading } = useSubmitHandler({
    onSuccess: () => {
      onClose();
      handleSuccess();
    },
    onError: () => setErrorMessage(translations.errorMessage),
  });

  const onDialogClose = () => {
    setErrorMessage(null);
    onClose();
  };

  const initialServices = useMemo(() => {
    const centerPackages =
      appointment.packages.map((pkg) => ({
        id: pkg.centerPackageId,
        addOnIds: pkg.addOns.map((addOn) => addOn.centerAddOnId),
        studyIds: pkg.offerings.map((study) => study.id),
      })) || [];

    const centerOfferings =
      appointment.offerings.map((offering) => ({
        id: offering.centerOfferingId,
        side: offering.side,
        addOnIds: offering.addOns?.map((addOn) => addOn.centerAddOnId) || [],
        studyIds: [offering.id],
      })) || [];

    return [...centerPackages, ...centerOfferings];
  }, [appointment.packages, appointment.offerings]);

  const initialAddOnsIds =
    appointment.addOns.map((addOn) => addOn.centerAddOnId) || [];

  const optionsProps = useCenterServiceOptions({
    centerConfig: appointment.center,
    initialServices,
    initialAddOnsIds,
  });

  const {
    isInitialized,
    summary,
    selectedCenterServices,
    selectedAddOns,
    isDirty,
    isAllCenterServicesValid,
  } = optionsProps;

  const actionButtons = useActionButtons({
    handleClose: onDialogClose,
    handleSubmit: () =>
      onSubmit({
        appointmentId: appointment.id || "",
        selectedAddOns,
        selectedCenterServices,
      }),
    isLoading,
    errorMessage,
    isValid: isAllCenterServicesValid && isDirty,
  });

  return (
    <StatusDialog
      open={isOpened}
      title={translations.title}
      actionButtons={actionButtons}
      errorMessage={errorMessage}
      isLoading={isLoading}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          maxWidth: 800,
        },
      }}
    >
      {!appointment.center || !optionsProps.offerings || !isInitialized ? (
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          minHeight={150}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box display="flex" gap={8}>
          <Box display="flex" flexDirection="column" gap={4}>
            <AppointmentModifyServicesForm
              optionsProps={optionsProps}
              isLoading={isLoading}
              centerConfig={appointment.center}
              isSubmitting={isLoading}
            />
            <SelectAddOnsForm
              centerConfig={appointment.center}
              addOns={optionsProps.addOns}
              isSubmitting={isLoading}
              selectedAddOns={optionsProps.selectedAddOns}
              onAddOnsChange={optionsProps.setSelectedAddOns}
            />
          </Box>
          <Box minWidth={280} maxWidth={280} flexShrink={0} top={0}>
            <AppointmentSummaryDetails
              centerConfig={appointment.center}
              selectedCenterServices={selectedCenterServices}
              selectedAddOns={optionsProps.selectedAddOns}
              discountSum={summary.discountSum}
              priceWithDiscount={summary.priceWithDiscount}
              centerAddOns={optionsProps.addOns || []}
            />
          </Box>
        </Box>
      )}
    </StatusDialog>
  );
};

export default AppointmentModifyServicesDialog;
