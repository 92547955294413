import { createClient } from "@supabase/supabase-js";
import { SUPABASE_TOKEN } from "../common/constants";

const { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY } = process.env;

export const setSupabaseAccessToken = (token: string): void => {
  localStorage.setItem(SUPABASE_TOKEN, token);
};

export const createSupabaseClient = () => {
  const token =
    localStorage.getItem(SUPABASE_TOKEN) || REACT_APP_SUPABASE_KEY || "";

  if (!REACT_APP_SUPABASE_URL || !REACT_APP_SUPABASE_KEY) {
    throw new Error("Supabase lonfig not found");
  }

  const headers: { [key: string]: string } = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const supabaseClient = createClient(
    REACT_APP_SUPABASE_URL,
    REACT_APP_SUPABASE_KEY,
    {
      global: {
        headers,
      },
    },
  );

  return supabaseClient;
};
