import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import formatISO from "date-fns/formatISO";

import { CenterTimeSlot } from "./types";
import { centerTimeSlotsRequest } from "./constants";
import { createSupabaseClient } from "../../../hooks/useSupabase";

const getCenterTimeSlotsForMonth = async (params: {
  centerId: string;
  monthDate: Date;
  abortSignal: AbortSignal | null;
}) => {
  const supabaseClient = createSupabaseClient();
  const { centerId, monthDate, abortSignal } = params;

  const startOfMonthISO = formatISO(subDays(startOfMonth(monthDate), 1));
  const endOfMonthISO = formatISO(addDays(endOfMonth(monthDate), 1));

  const dateOrFilter = [
    `and(started_at.gte.${startOfMonthISO},started_at.lte.${endOfMonthISO})`,
    `and(ended_at.gte.${startOfMonthISO},ended_at.lte.${endOfMonthISO})`,
    `and(started_at.lte.${startOfMonthISO},ended_at.gte.${startOfMonthISO})`,
    `and(started_at.lte.${endOfMonthISO},ended_at.gte.${endOfMonthISO})`,
  ].join(",");

  const typeOrFilter = [
    "and(type.eq.appointment_booked,expires_at.is.null)",
    "and(type.eq.planned_closures,expires_at.is.null)",
    "and(type.eq.appointment_reserved,expires_at.gte.NOW())",
  ].join(",");

  let query = supabaseClient
    .from("center_time_slots")
    .select(centerTimeSlotsRequest)
    .eq("center_id", centerId)
    .or(dateOrFilter)
    .or(typeOrFilter)
    .returns<CenterTimeSlot[]>();

  if (abortSignal) {
    query = query.abortSignal(abortSignal);
  }

  const { data, error } = await query;

  if (error) throw new Error(error.message);

  return data;
};

export default getCenterTimeSlotsForMonth;
