import React, { FC, useState } from "react";
import { subDays } from "date-fns";
import { useDebounce } from "react-use";
import { v4 as generateKey } from "uuid";
import { ADMIN_APPOINTMENTS_LIST_DURATION_DAYS } from "../../../common/constants";
import { DateRange, DateRangePickerProps } from "../../Unknown/DateRangePicker";
import PatientInformationDrawerContainer from "../../Patient/PatientInformationDrawerContainer";
import CustomerInformationDrawerContainer from "../../Customer/CustomerInformationDrawerContainer";
import { useAppContext } from "../../Unknown/AppContext";
import Box from "../../Unknown/Box";
import AppointmentTable from "../AppointmentTable";
import usePatientDrawerContainer from "./usePatientDrawerContainer";
import useCustomerDrawerContainer from "./useCustomerDrawerContainer";
import { useTableAppointments } from "./useTableAppointments";
import useStyles from "./useStyles";

type AppointmentsTableProps = {
  role?: string;
  date: Date;
};

const defaultToDate = new Date();
const defaultFromDate = subDays(
  defaultToDate,
  ADMIN_APPOINTMENTS_LIST_DURATION_DAYS - 1,
);

const AppointmentTableContainer: FC<AppointmentsTableProps> = ({
  role,
  date,
}) => {
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    defaultFromDate,
    defaultToDate,
  ]);
  const [debouncedDateRange, setDebouncedDateRange] = useState<DateRange<Date>>(
    [defaultFromDate, defaultToDate],
  );

  useDebounce(
    () => {
      setDebouncedDateRange(dateRange);
    },
    1000,
    [dateRange],
  );

  const [tableKey, setTableKey] = useState(generateKey());

  const { tableRoot } = useStyles();

  const {
    databaseUserData: { centerIds },
  } = useAppContext();

  const { appointments, isLoading, refetch } = useTableAppointments({
    centerIds,
    date,
    dateRange: role === "super_admin" ? debouncedDateRange : undefined,
    onRefetch: () => setTableKey(generateKey()),
  });

  const customerDrawerContainerProps = useCustomerDrawerContainer();

  const { isOpen: isPatientDrawerOpen, props: patientDrawerContainerProps } =
    usePatientDrawerContainer({
      refetchAppointment: refetch,
    });

  const dateRangePickerProps: DateRangePickerProps<Date> = {
    value: dateRange,
    onChange: (newRange) => setDateRange(newRange),
  };

  return (
    <>
      <Box className={tableRoot}>
        <AppointmentTable
          role={role}
          refetch={refetch}
          appointments={appointments}
          isLoading={isLoading && !appointments?.length}
          dateRangePickerProps={dateRangePickerProps}
          tableKey={tableKey}
        />
      </Box>
      {isPatientDrawerOpen && (
        <PatientInformationDrawerContainer {...patientDrawerContainerProps} />
      )}
      <CustomerInformationDrawerContainer {...customerDrawerContainerProps} />
    </>
  );
};

export default AppointmentTableContainer;
