import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useLabelTranslations = () => {
  const { formatMessage } = useIntl();

  const labelTranslations = useMemo(() => {
    return {
      labelName: formatMessage(messages.labelName),
      labelPrice: formatMessage(messages.labelPrice),
      labelStat: formatMessage(messages.labelStat),
      labelAppliesTo: formatMessage(messages.labelAppliesTo),
      labelContactingPatientRequired: formatMessage(
        messages.labelContactingPatientRequired,
      ),
    };
  }, [formatMessage]);

  return labelTranslations;
};

export default useLabelTranslations;
