import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";

import { formatLocaleTimeString } from "../../../common/dateHelpers";
import CenterServiceAppointmentCardGrid from "../../CenterService/CenterServiceAppointmentCardGrid";
import Card from "../../Unknown/Card";
import CardContent from "../../Unknown/CardContent";
import CardHeader from "../../Unknown/CardHeader";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import AppointmentAdditionalView from "../AppointmentAdditionalView";
import useCenterServiceAppointmentCardGrid from "./useCenterServiceAppointmentCardGrid";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";
import useActions from "./useActions";
import { Appointment } from "./types";
import Button from "../../Unknown/Button";
import Box from "../../Unknown/Box";
import AppointmentModifyServicesDialogContainer from "../AppointmentModifyServicesDialogContainer";
import checkIsCanBeModified from "./checkIsCanBeModified";
import AppointmentAddOnsList from "./addOnsList";

export type AppointmentPreviewCardProps = {
  appointment: Appointment;
  title: string;
  onReadStudyClick: ({ id, url }: { id: string; url: string }) => void;
  onEditStudyClick: ({ url }: { url: string }) => void;
  onAddAdditionalViewClick: (id: string) => void;
  refetch: () => void;
};

const AppointmentPreviewCard: FC<AppointmentPreviewCardProps> = ({
  appointment,
  title,
  refetch,
  onReadStudyClick,
  onEditStudyClick,
  onAddAdditionalViewClick,
}) => {
  const { labelRoot, linkRoot } = useStyles();
  const { role } = useParams<{ role?: DatabaseEnum["center_user_role"] }>();

  const translations = useTranslations({
    scheduledAt: appointment.createdAtCenterTimezone,
    originalAppointmentAt: appointment.originalAppointment?.startedAt,
    additionalAppointmentAt: appointment.additionalViewsAppointment?.startedAt,
  });

  const isCanBeModified = checkIsCanBeModified({
    appointment,
    role,
  });

  const centerServiceAppointmentCardGridProps =
    useCenterServiceAppointmentCardGrid({
      appointment,
      onAddAdditionalViewClick,
      onReadStudyClick,
      onEditStudyClick,
    });

  const actions = useActions({ appointment });

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <Grid container wrap="nowrap" direction="row" spacing={3}>
            {actions?.map(({ id, node }) => (
              <Grid item key={id}>
                {node}
              </Grid>
            ))}
          </Grid>
        }
      />
      <CardContent>
        <Grid container spacing={4} direction="column" wrap="nowrap">
          <Grid item container direction="row" spacing={4}>
            <Grid item xs={4}>
              <Typography className={labelRoot}>
                {translations.labelDate}
              </Typography>
              <Typography variant="body2">
                {appointment.bookedAtCenterTimezone.toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={labelRoot}>
                {translations.labelTime}
              </Typography>
              <Typography variant="body2">
                {formatLocaleTimeString(appointment.bookedAtCenterTimezone)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={labelRoot}>
                {translations.labelCenter}
              </Typography>
              <Typography variant="body2">{appointment.center.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={labelRoot}>
                {translations.labelCustomer}
              </Typography>
              <Typography variant="body2">
                <Typography
                  variant="body2"
                  className={linkRoot}
                  component={Link}
                  to={appointment.customer.link}
                >
                  {`${appointment.customer.lastName}, ${appointment.customer.firstName}`}
                </Typography>
                <Typography ml={2} component="span" variant="body2">
                  {translations.helperScheduledAt}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" spacing={0}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography className={labelRoot}>
                {translations.labelCenterServices}
              </Typography>
              {isCanBeModified && appointment && (
                <AppointmentModifyServicesDialogContainer
                  appointment={appointment}
                  handleSuccess={refetch}
                />
              )}
            </Box>
            <CenterServiceAppointmentCardGrid
              {...centerServiceAppointmentCardGridProps}
            />
          </Grid>
          <Grid item container spacing={4}>
            <AppointmentAddOnsList appointment={appointment} />
            {appointment.onScreeningFormDownloadClick && (
              <Grid item xs={12} lg={6}>
                <Button
                  size="small"
                  onClick={
                    appointment.onScreeningFormDownloadClick || undefined
                  }
                >
                  {translations.downloadScreeningForm}
                </Button>
              </Grid>
            )}
          </Grid>
          {!!appointment.originalAppointment && (
            <AppointmentAdditionalView
              title={translations.originalAppointment}
              linkText={translations.originalAppointmentAt}
              link={appointment.originalAppointment.link}
            />
          )}
          {!!appointment.additionalViewsAppointment && (
            <AppointmentAdditionalView
              title={translations.additionalViewsAppointment}
              linkText={translations.additionalAppointmentAt}
              link={appointment.additionalViewsAppointment.link}
            />
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentPreviewCard;
