import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Box from "../../../Unknown/Box";
import Tooltip from "../../../Unknown/Tooltip";
import IconButton from "../../../Unknown/IconButton";
import {
  AppointmentOfferingColumnItemAlert,
  AppointmentOfferingRowItem,
} from "../types";
import ReportDeliveryDeadlineCountdown from "../../../Report/ReportDeliveryDeadlineCountdown";

import {
  AssignmentLateOutlinedIcon,
  TimerOutlinedIcon,
  AssignmentTurnedInOutlinedIcon,
  QueueOutlinedIcon,
  ArrowCircleUpIcon,
  SupportAgentIcon,
} from "../../../Unknown/Icons";
import getOptionalArrayItem from "../../../../common/getOptionalArrayItem";
import { AppointmentFlag } from "../../../Appointment/AppointmentTableFilterFlags";

interface ColumnValue {
  value: string;
  label: string;
}

export type ColumnStatusTranslations = {
  columnAlert: string;
  tooltipStat: string;
  tooltipAdditionalViews: string;
  tooltipUnexpectedFindings: string;
  tooltipReceiptConfirmed: string;
  tooltipHasPriority: string;
  tooltipContactingPatientRequired: string;
};

const getColumnAlert = (params: {
  columnItem: AppointmentOfferingColumnItemAlert;
  translations: ColumnStatusTranslations;
  headerClassName?: string;
}): GridColDef<AppointmentOfferingRowItem, ColumnValue[], string> => {
  const { columnItem, translations, headerClassName } = params;

  return {
    field: columnItem.field,
    headerName: translations.columnAlert,
    headerClassName,
    disableColumnMenu: true,
    sortable: false,
    type: "singleSelect",
    width: 100,
    align: "center",
    getApplyQuickFilterFn: () => null,
    valueFormatter: ({ value }) => {
      return value.map(({ label }) => label).join(",");
    },
    valueGetter: ({ row }) => {
      return [
        ...getOptionalArrayItem(row.isStat, {
          value: AppointmentFlag.isStat,
          label: translations.tooltipStat,
        }),
        ...getOptionalArrayItem(row.isContactingPatientRequired, {
          value: AppointmentFlag.isContactingPatientRequired,
          label: translations.tooltipContactingPatientRequired,
        }),
        ...getOptionalArrayItem(row.isAdditionalViews, {
          value: AppointmentFlag.isAdditionalViews,
          label: translations.tooltipAdditionalViews,
        }),
        ...getOptionalArrayItem(row.hasPriority, {
          value: AppointmentFlag.hasPriority,
          label: translations.tooltipHasPriority,
        }),
        ...getOptionalArrayItem(
          row.hasUnexpectedFindings &&
            !row.isUnexpectedFindingsReceiptConfirmed,
          {
            value: AppointmentFlag.hasUnexpectedFindings,
            label: translations.tooltipUnexpectedFindings,
          },
        ),
        ...getOptionalArrayItem(row.isUnexpectedFindingsReceiptConfirmed, {
          value: AppointmentFlag.isReceiptConfirmed,
          label: translations.tooltipReceiptConfirmed,
        }),
      ];
    },
    renderCell: ({ row }) => {
      const tooltipPopperProps = {
        sx: {
          maxWidth: 100,
        },
      };

      return (
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          {row.isStat && (
            <Tooltip
              title={translations.tooltipStat}
              slotProps={{
                popper: tooltipPopperProps,
              }}
              arrow
            >
              <IconButton size="small">
                <TimerOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {row.isContactingPatientRequired && (
            <Tooltip
              title={translations.tooltipContactingPatientRequired}
              slotProps={{
                popper: tooltipPopperProps,
              }}
              arrow
            >
              <IconButton size="small">
                <SupportAgentIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {row.isAdditionalViews && (
            <Tooltip
              title={translations.tooltipAdditionalViews}
              arrow
              slotProps={{
                popper: tooltipPopperProps,
              }}
            >
              <IconButton size="small">
                <QueueOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {row.hasPriority && (
            <Tooltip
              title={translations.tooltipHasPriority}
              arrow
              slotProps={{
                popper: tooltipPopperProps,
              }}
            >
              <IconButton size="small">
                <ArrowCircleUpIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {row.hasUnexpectedFindings &&
            !row.isUnexpectedFindingsReceiptConfirmed && (
              <Tooltip
                title={translations.tooltipUnexpectedFindings}
                arrow
                slotProps={{
                  popper: tooltipPopperProps,
                }}
              >
                <IconButton size="small">
                  <AssignmentLateOutlinedIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          {row.isUnexpectedFindingsReceiptConfirmed && (
            <Tooltip
              title={translations.tooltipReceiptConfirmed}
              arrow
              slotProps={{
                popper: tooltipPopperProps,
              }}
            >
              <IconButton size="small">
                <AssignmentTurnedInOutlinedIcon color="success" />
              </IconButton>
            </Tooltip>
          )}
          {row.readyToReadAtCenterTimezone && (
            <Box mt={1} mx="auto">
              <ReportDeliveryDeadlineCountdown
                reportDeliveryDeadlineHours={row.reportDeliveryDeadlineHours}
                readyToReadAtCenterTimezone={row.readyToReadAtCenterTimezone}
                timezone={row.center.timezone}
              />
            </Box>
          )}
        </Box>
      );
    },
  };
};

export default getColumnAlert;
