import React from "react";
import {
  GridCellParams,
  GridColDef,
  GridComparatorFn,
} from "../../../Unknown/DataGrid";
import PatientPreview from "../../../Patient/PatientPreview";
import {
  AppointmentOfferingColumnItemPatient,
  AppointmentOfferingRowItem,
} from "../types";

type ColumnValue = {
  mrn: string;
  firstName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: Date | null;
  phoneNumber: string;
};

export type ColumnPatientTranslations = {
  columnPatient: string;
};

const sortComparator: GridComparatorFn<ColumnValue> = (
  currentValue,
  compareValue,
) => {
  return currentValue.fullName.localeCompare(compareValue.fullName);
};

const getColumnPatient = (params: {
  columnItem: AppointmentOfferingColumnItemPatient;
  translations: ColumnPatientTranslations;
  onNameClick?: (id: string) => void;
}): GridColDef<AppointmentOfferingRowItem, ColumnValue> => {
  const { columnItem, translations, onNameClick } = params;
  const { field, localeFormat } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 1,
    headerName: translations.columnPatient,
    sortComparator,
    valueGetter: (getterParams) => {
      const { appointmentPatient, patient } = getterParams.row;
      const { firstName, lastName, dateOfBirth, phoneNumber } =
        appointmentPatient;
      const { mrn } = patient;
      const fullName = `${lastName}, ${firstName}`;

      return {
        mrn,
        fullName,
        firstName,
        lastName,
        dateOfBirth,
        phoneNumber,
      };
    },
    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      const { mrn, fullName, dateOfBirth, phoneNumber } = renderParams.value;

      return (
        <PatientPreview
          fullName={fullName}
          dateOfBirth={dateOfBirth}
          phoneNumber={phoneNumber}
          locale={
            localeFormat === "center"
              ? renderParams.row.center.locale
              : undefined
          }
          onNameClick={onNameClick ? () => onNameClick(mrn) : undefined}
        />
      );
    },
    getApplyQuickFilterFn: (value: string) => {
      const searchRegExp = new RegExp(value.trim(), "i");

      return (
        quickFilterParams: GridCellParams<
          AppointmentOfferingRowItem,
          ColumnValue
        >,
      ): boolean => {
        if (!quickFilterParams.value) return false;

        const { firstName, lastName } = quickFilterParams.value;

        return searchRegExp.test(firstName) || searchRegExp.test(lastName);
      };
    },
  };
};

export default getColumnPatient;
