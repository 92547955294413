import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { DateRange } from "@mui/x-date-pickers-pro";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { createSupabaseClient } from "../../../hooks/useSupabase";
import { useAppContext } from "../../Unknown/AppContext";
import useTranslations from "../../Unknown/AppContext/useTranslations";
import { useUIContext } from "../../Unknown/UIContext";
import filterDashboardAppointments, {
  MinimumStartFilterMinutes,
} from "./filterAppointments";
import getDashboardAppointments, {
  DashboardAppointment,
} from "./getAppointments";

const getConfig = (
  role: string,
): {
  minimumStartFilterMinutes?: MinimumStartFilterMinutes;
  statuses: DatabaseEnum["appointment_status"][];
} => {
  switch (role) {
    case "receptionist":
      return {
        minimumStartFilterMinutes: "startOfDay",
        statuses: [
          "incomplete",
          "confirmed",
          "intake_complete",
          "no_show",
          "checked_in",
          "studies_unread",
          "studies_read",
          "reports_sent",
          "reports_error",
        ],
      };
    case "technologist":
      return {
        minimumStartFilterMinutes: 60,
        statuses: [
          "confirmed",
          "checked_in",
          "incomplete",
          "intake_complete",
          "studies_unread",
          "studies_read",
          "reports_sent",
          "reports_error",
        ],
      };
    case "regional_admin":
    case "super_admin":
      return {
        statuses: [
          "incomplete",
          "confirmed",
          "checked_in",
          "abandoned",
          "checkout",
          "intake_complete",
          "no_show",
          "canceled",
          "studies_unread",
          "studies_read",
          "reports_sent",
          "reports_error",
        ],
      };
    default:
      return {
        minimumStartFilterMinutes: "startOfDay",
        statuses: [],
      };
  }
};

export const useTableAppointments = (params: {
  dateRange?: DateRange<Date>;
  centerIds: string[];
  date: Date;
  onRefetch: () => void;
}) => {
  const { centerIds, date, dateRange, onRefetch } = params;
  const supabase = useMemo(() => createSupabaseClient(), []);
  const translations = useTranslations();
  const { role: urlParamRole } = useParams();

  const { centers } = useAppContext();

  const timezone = centers.length === 1 ? centers[0].timezone : null;

  const { setAlert } = useUIContext();

  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(true);
  const [appointments, setAppointments] = useState<DashboardAppointment[]>([]);

  const fetch = useCallback(async () => {
    try {
      setIsAppointmentsLoading(true);
      onRefetch();

      const { statuses, minimumStartFilterMinutes } = getConfig(
        urlParamRole || "",
      );

      const data = await getDashboardAppointments({
        supabase,
        centerIds,
        date,
        statuses,
        dateRange,
        timezone,
      });

      const filteredData = filterDashboardAppointments({
        appointments: data,
        minimumStartFilterMinutes,
      });

      setAppointments(filteredData);
    } catch (error) {
      setAlert({
        isShown: true,
        message: translations.defaultError,
        severity: "error",
      });

      setAppointments([]);
    } finally {
      setIsAppointmentsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    centerIds,
    date,
    dateRange,
    supabase,
    translations.defaultError,
    urlParamRole,
    setAlert,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    appointments,
    isLoading: isAppointmentsLoading,
    refetch: fetch,
  };
};
