import { useCallback, useEffect, useMemo } from "react";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";

import useDataState from "../../../hooks/useDataState";
import { createSupabaseClient } from "../../../hooks/useSupabase";
import { AddOn, AddOnAppliesTo } from "./types";

export type SupabaseAddOn = Pick<
  DatabaseRow<"center_add_ons">,
  "id" | "name" | "type" | "price" | "applies_to"
>;

const supabaseBookingAddOnsRequest = `
id,
name,
type,
applies_to,
price,
visibility
`;

export const parseAddOn = (addOn: SupabaseAddOn): AddOn => {
  return {
    id: addOn.id,
    name: addOn.name,
    type: addOn.type,
    appliesTo: (addOn.applies_to || "appointment") as AddOnAppliesTo,
    price: addOn.price,
  };
};

const useBookingAddOns = (centerId?: string) => {
  const supabase = useMemo(() => createSupabaseClient(), []);

  const [dataState, updateDataState] = useDataState<AddOn[]>();

  const getAddOnsRequest = useCallback(async () => {
    try {
      if (!centerId) return;

      updateDataState({ status: "loading" });

      const response = await supabase
        .from("center_add_ons")
        .select(supabaseBookingAddOnsRequest)
        .eq("center_id", centerId)
        .eq("visibility", "public")
        .returns<DatabaseRow<"center_add_ons">[]>();

      if (response.error) {
        throw new Error(response.error.message);
      }

      if (response.data) {
        const parsedAddOns = response.data.map(parseAddOn);
        updateDataState({ status: "success", data: parsedAddOns });
      }
    } catch (err) {
      updateDataState({ status: "error", error: (err as Error).message });
    }
  }, [centerId, supabase, updateDataState]);

  useEffect(() => {
    getAddOnsRequest();
  }, [getAddOnsRequest]);

  return dataState;
};

export default useBookingAddOns;
