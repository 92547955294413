import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthContext } from "../../Auth/AuthContext";
import SignInScreen from "../../Auth/SignInScreen";
import { AppContextProvider } from "../AppContext";
import AuthenticatedRoot from "../AuthenticatedRoot";
import LoadingScreen from "../LoadingScreen";

const Root: React.FC = () => {
  const { isLoading, isAuthenticated, userData } = useAuthContext();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated || !userData) {
    return (
      <Routes>
        <Route path="/" element={<SignInScreen />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  return (
    <AppContextProvider authUserData={userData}>
      <AuthenticatedRoot />
    </AppContextProvider>
  );
};

export default Root;
