import { defineMessages } from "react-intl";

export default defineMessages({
  labelDate: {
    id: "Appointment.AppointmentPreviewCard.labelDate",
    defaultMessage: "Date",
  },
  labelTime: {
    id: "Appointment.AppointmentPreviewCard.labelTime",
    defaultMessage: "Time",
  },
  labelCenter: {
    id: "Appointment.AppointmentPreviewCard.labelCenter",
    defaultMessage: "Location",
  },
  labelCustomer: {
    id: "Appointment.AppointmentPreviewCard.labelCustomer",
    defaultMessage: "Scheduled by",
  },
  helperScheduledAt: {
    id: "Appointment.AppointmentPreviewCard.helperScheduledAt",
    defaultMessage: "on {dateValue} at {timeValue}",
  },
  originalAppointmentAt: {
    id: "Appointment.AppointmentPreviewCard.helperOriginalAppointmentAt",
    defaultMessage: "{dateValue} at {timeValue}",
  },
  labelCenterServices: {
    id: "Appointment.AppointmentPreviewCard.labelCenterServices",
    defaultMessage: "Offerings",
  },
  labelAddOns: {
    id: "Appointment.AppointmentPreviewCard.labelAddOns",
    defaultMessage: "Add-ons",
  },
  statusUnknown: {
    id: "Appointment.AppointmentPreviewCard.statusUnknown",
    defaultMessage: "Unknown status",
  },
  statusCheckout: {
    id: "Appointment.AppointmentPreviewCard.statusCheckout",
    defaultMessage: "Checkout",
  },
  statusIncomplete: {
    id: "Appointment.AppointmentPreviewCard.statusIncomplete",
    defaultMessage: "Incomplete",
  },
  statusAbandoned: {
    id: "Appointment.AppointmentPreviewCard.statusAbandoned",
    defaultMessage: "Abandoned",
  },
  statusConfirmed: {
    id: "Appointment.AppointmentPreviewCard.statusConfirmed",
    defaultMessage: "Confirmed",
  },
  statusCanceled: {
    id: "Appointment.AppointmentPreviewCard.statusCanceled",
    defaultMessage: "Canceled",
  },
  statusStudiesUnread: {
    id: "Appointment.AppointmentPreviewCard.statusStudiesUnread",
    defaultMessage: "Studies unread",
  },
  statusStudiesRead: {
    id: "Appointment.AppointmentPreviewCard.statusStudiesRead",
    defaultMessage: "Studies read",
  },
  statusReportsSent: {
    id: "Appointment.AppointmentPreviewCard.statusReportsSent",
    defaultMessage: "Reports sent",
  },
  statusReportsError: {
    id: "Appointment.AppointmentPreviewCard.statusReportsError",
    defaultMessage: "Reports error",
  },
  statusCheckedIn: {
    id: "Appointment.AppointmentPreviewCard.statusCheckedIn",
    defaultMessage: "Checked in",
  },
  statusIntakeComplete: {
    id: "Appointment.AppointmentPreviewCard.statusIntakeComplete",
    defaultMessage: "Intake complete",
  },
  statusNoShow: {
    id: "Appointment.AppointmentPreviewCard.statusNoShow",
    defaultMessage: "No show",
  },
  tooltipStat: {
    id: "Appointment.AppointmentPreviewCard.tooltipStat",
    defaultMessage: "STAT",
  },
  tooltipPriority: {
    id: "Appointment.AppointmentPreviewCard.tooltipPriority",
    defaultMessage: "Priority",
  },
  additionalViews: {
    id: "Appointment.AppointmentPreviewCard.additionalViews",
    defaultMessage: "Additional views",
  },
  originalAppointment: {
    id: "Appointment.AppointmentPreviewCard.originalAppointment",
    defaultMessage: "Original appointment:",
  },
  additionalViewsAppointment: {
    id: "Appointment.AppointmentPreviewCard.additionalViewsAppointment",
    defaultMessage: "Additional views appointment:",
  },
  tooltipUnexpectedFindings: {
    id: "Appointment.AppointmentPreviewCard.tooltipUnexpectedFindings",
    defaultMessage: "Unexpected findings",
  },
  tooltipReceiptConfirmed: {
    id: "Appointment.AppointmentPreviewCard.tooltipReceiptConfirmed",
    defaultMessage: "Receipt confirmed",
  },
  downloadScreeningForm: {
    id: "Appointment.AppointmentPreviewCard.downloadScreeningForm",
    defaultMessage: "Download screening form",
  },
  tooltipRequiresContactingPatient: {
    id: "Appointment.AppointmentPreviewCard.tooltipRequiresContactingPatient",
    defaultMessage: "Contact Patient",
  },
  perStudyAddOn: {
    id: "Appointment.AppointmentPreviewCard.perStudyAddOn",
    defaultMessage: "{addOn} for {study}",
  },
  left: {
    id: "Appointment.AppointmentPreviewCard.left",
    defaultMessage: "Left",
  },
  right: {
    id: "Appointment.AppointmentPreviewCard.right",
    defaultMessage: "Right",
  },
});
