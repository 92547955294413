import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    const columnTranslations = {
      columnStatus: formatMessage(messages.columnStatus),
      columnPatient: formatMessage(messages.columnPatient),
      columnPatientName: formatMessage(messages.columnPatientName),
      columnTime: formatMessage(messages.columnTime),
      columnDate: formatMessage(messages.columnDate),
      columnBookedTime: formatMessage(messages.columnBookedTime),
      columnBookedDate: formatMessage(messages.columnBookedDate),
      columnLocation: formatMessage(messages.columnLocation),
      columnLocationState: formatMessage(messages.columnLocationState),
      columnLocationZip: formatMessage(messages.columnLocationZip),
      columnOffering: formatMessage(messages.columnOffering),
      columnOfferingStudies: formatMessage(messages.columnOfferingStudies),
      columnFullOfferings: formatMessage(messages.columnFullOfferings),
      columnFullPackages: formatMessage(messages.columnFullPackages),
      columnFullAddOns: formatMessage(messages.columnFullAddOns),
      columnCustomer: formatMessage(messages.columnCustomer),
      columnPayments: formatMessage(messages.columnPayments),
      columnBookingScenario: formatMessage(messages.columnBookingScenario),
      columnBookedBy: formatMessage(messages.columnBookedBy),
      columnCreatedAt: formatMessage(messages.columnCreatedAt),
      columnPatientAddress: formatMessage(messages.columnPatientAddress),
      columnPatientCity: formatMessage(messages.columnPatientCity),
      columnPatientEmail: formatMessage(messages.columnPatientEmail),
      columnPatientAge: formatMessage(messages.columnPatientAge),
      columnPatientId: formatMessage(messages.columnPatientId),
      columnPatientRace: formatMessage(messages.columnPatientRace),
      columnPatientSex: formatMessage(messages.columnPatientSex),
      columnPatientState: formatMessage(messages.columnPatientState),
      columnAppointmentTotal: formatMessage(messages.columnAppointmentTotal),
      columnPatientAddressLine1: formatMessage(
        messages.columnPatientAddressLine1,
      ),
      columnPatientAddressLine2: formatMessage(
        messages.columnPatientAddressLine2,
      ),
      columnPatientZip: formatMessage(messages.columnPatientZip),
      columnPatientDOB: formatMessage(messages.columnPatientDOB),
      columnPatientPhone: formatMessage(messages.columnPatientPhone),
      columnAlert: formatMessage(messages.columnAlert),
      columnFlags: formatMessage(messages.columnFlags),
      columnBtnOptions: formatMessage(messages.columnBtnOptions),
      columnBtnView: formatMessage(messages.columnBtnView),

      statusCheckout: formatMessage(messages.statusCheckout),
      statusIncomplete: formatMessage(messages.statusIncomplete),
      statusAbandoned: formatMessage(messages.statusAbandoned),
      statusConfirmed: formatMessage(messages.statusConfirmed),
      statusCanceled: formatMessage(messages.statusCanceled),
      statusIntakeComplete: formatMessage(messages.statusIntakeComplete),
      statusCheckedIn: formatMessage(messages.statusCheckedIn),
      statusNoShow: formatMessage(messages.statusNoShow),
      statusStudiesUnread: formatMessage(messages.statusStudiesUnread),
      statusStudiesRead: formatMessage(messages.statusStudiesRead),
      statusReportsSent: formatMessage(messages.statusReportsSent),
      statusReportsError: formatMessage(messages.statusReportsError),

      flagStat: formatMessage(messages.flagStat),
      flagAdditionalViews: formatMessage(messages.flagAdditionalViews),
      flagUnexpectedFindings: formatMessage(messages.flagUnexpectedFindings),
      flagReceiptConfirmed: formatMessage(messages.flagReceiptConfirmed),
      flagHasPriority: formatMessage(messages.flagHasPriority),
      flagRequiresGuardian: formatMessage(messages.flagRequiresGuardian),

      optionsBtnCheckIn: formatMessage(messages.optionsBtnCheckIn),
      optionsBtnLogIn: formatMessage(messages.optionsBtnLogIn),

      btnView: formatMessage(messages.btnView),

      sideRight: formatMessage(messages.sideRight),
      sideLeft: formatMessage(messages.sideLeft),

      providerStripe: formatMessage(messages.providerStripe),
      providerCash: formatMessage(messages.providerCash),
      providerUnknown: formatMessage(messages.providerUnknown),

      scenarioPatient: formatMessage(messages.scenarioPatient),
      scenarioLegalGuardian: formatMessage(messages.scenarioLegalGuardian),
      scenarioOtherPerson: formatMessage(messages.scenarioOtherPerson),

      tooltipStat: formatMessage(messages.tooltipStat),
      tooltipAdditionalViews: formatMessage(messages.tooltipAdditionalViews),
      tooltipUnexpectedFindings: formatMessage(
        messages.tooltipUnexpectedFindings,
      ),
      tooltipReceiptConfirmed: formatMessage(messages.tooltipReceiptConfirmed),
      tooltipHasPriority: formatMessage(messages.tooltipHasPriority),
      tooltipRequiresGuardian: formatMessage(messages.tooltipRequiresGuardian),
      tooltipRequiresContactingPatient: formatMessage(
        messages.tooltipRequiresContactingPatient,
      ),
      years: formatMessage(messages.years),
      containsAny: formatMessage(messages.containsAny),
      containsAll: formatMessage(messages.containsAll),
      for: formatMessage(messages.for),
      left: formatMessage(messages.left),
      right: formatMessage(messages.right),
    };

    return { columnTranslations };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
