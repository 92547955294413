import { useCallback, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import getAppointmentData from "./getAppointmentData";
import { Appointment } from "./types";
import useTranslations from "./useTranslations";
import supabaseClient from "../../../common/supabaseClient";

const useAppointmentData = (appointmentId?: string) => {
  const { runAsyncFunction } = useDefaultErrorWrapper();
  const { translations } = useTranslations();
  const { appointmentNotFound } = translations;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [data, setData] = useState<Appointment | null>(null);

  const fetch = useCallback(async () => {
    setIsLoading(true);
    if (!appointmentId) return;

    try {
      const appointmentResult = await runAsyncFunction(
        getAppointmentData,
        appointmentId,
      );

      if (!appointmentResult) {
        throw new Error(appointmentNotFound);
      }

      setData(appointmentResult);
    } catch (error) {
      setErrorMessage((error as Error).message);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  }, [appointmentId, appointmentNotFound, runAsyncFunction]);

  useEffectOnce(() => {
    const getAppointment = async () => {
      await fetch();
    };

    getAppointment();
  });

  useEffect(() => {
    const appointmentSubscription = supabaseClient
      .channel("schema-db-changes")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "appointments" },
        async (payload) => {
          if (
            payload.new.id === appointmentId &&
            (payload.new.status !== payload.old.status ||
              payload.new.has_priority !== payload.old.has_priority)
          ) {
            await fetch();
          }
        },
      )
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "appointment_offerings" },
        async (payload) => {
          if (
            payload.new.appointment_id === appointmentId &&
            payload.new.status !== payload.old.status
          ) {
            await fetch();
          }
        },
      )
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "appointment_refunds" },
        async (payload) => {
          if (payload.new.appointment_id === appointmentId) {
            await fetch();
          }
        },
      )
      .subscribe();

    return () => {
      supabaseClient.removeChannel(appointmentSubscription);
    };
  }, [appointmentId, fetch]);

  return {
    isLoading,
    errorMessage,
    data,
    refetch: fetch,
  };
};

export default useAppointmentData;
