import React from "react";
import { capitalCase } from "change-case";
import { GridColDef } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { AppointmentColumnItemAddOns, AppointmentRowItem } from "../types";

export type ColumnTranslations = {
  columnAddOns: string;
  for: string;
  left: string;
  right: string;
};

const formatService = (name: string, price: string | number) => {
  return `${capitalCase(name).replace(/\s+/g, "")}^${price}`;
};

const getColumnAddOns = (params: {
  columnItem: AppointmentColumnItemAddOns;
  translations: ColumnTranslations;
}) => {
  const { columnItem, translations } = params;

  const { field } = columnItem;

  const column: GridColDef<AppointmentRowItem, string> = {
    field,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnAddOns,
    valueGetter: (getterParams) => {
      const { addOns, offerings, packages } = getterParams.row;

      const formattedAddOns = addOns.map((addOn) => {
        const { name, cost } = addOn;

        return formatService(name, cost);
      });

      const formattedPackageAddOns = packages
        .map((pkg) => {
          return pkg.addOns.map((addOn) => {
            const packageAddOnName = `${addOn.name} ${translations.for} ${pkg.name}`;
            return formatService(packageAddOnName, addOn.cost);
          });
        })
        .flat();

      const formattedOfferingAddOns = offerings
        .map((offering) => {
          const offeringName = offering.side
            ? `${offering.name}(${
                translations[offering.side as keyof ColumnTranslations]
              })`
            : offering.name;
          return offering.addOns.map((addOn) => {
            const offeringAddOnName = `${addOn.name} ${translations.for} ${offeringName}`;
            return formatService(offeringAddOnName, addOn.cost);
          });
        })
        .flat();

      return [
        ...formattedAddOns,
        ...formattedPackageAddOns,
        ...formattedOfferingAddOns,
      ].join(";");
    },
    renderCell: ({ value }) => {
      return (
        <Typography variant="body2" style={{ wordBreak: "break-all" }}>
          {value}
        </Typography>
      );
    },
  };

  return column;
};

export default getColumnAddOns;
