import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { getLocaleCurrencySymbol } from "../../../common/currencyHelpers";

const messages = defineMessages({
  appliesToDescription: {
    id: "AddOn.CenterAddOnForm.appliesToDescription",
    defaultMessage:
      "If applied to study the price of the add-on will be multiplied by the number of studies in the package",
  },
  appointmentLabel: {
    id: "AddOn.CenterAddOnForm.appointmentLabel",
    defaultMessage: "Appointment",
  },
  studyLabel: {
    id: "AddOn.CenterAddOnForm.studyLabel",
    defaultMessage: "Study",
  },
});

const useTranslations = (params: { currencyCode: string }) => {
  const { currencyCode } = params;
  const { locale, formatMessage } = useIntl();

  const currencySymbol = getLocaleCurrencySymbol(currencyCode, locale);

  const translations = useMemo(() => {
    return {
      currencySymbol,
      appliesToDescription: formatMessage(messages.appliesToDescription),
      studyLabel: formatMessage(messages.studyLabel),
      appointmentLabel: formatMessage(messages.appointmentLabel),
    };
  }, [currencySymbol, formatMessage]);
  return translations;
};
export default useTranslations;
