import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import React, { FC, createContext, ReactNode, useContext } from "react";
import useSystemConfig, { SystemConfig } from "../../../hooks/useSystemConfig";
import { AuthUserData } from "../../Auth/AuthContext";
import LoadingScreen from "../LoadingScreen";
import { DatabaseUserData } from "./convertSupabaseDataToContextUser";
import usePatientSearch, { PatientsSearch } from "./usePatientSearch";
import useDatabaseUserData from "./useDatabaseUserData";
import useUserCenters, { UserCenter } from "./useUserCenters";

interface AppContextProps {
  authUserData: AuthUserData;
  databaseUserData: DatabaseUserData;
  updateLastUsedRole: (role: DatabaseEnum["center_user_role"]) => Promise<void>;
  systemConfig: SystemConfig;
  patientsSearch: PatientsSearch;
  centers: UserCenter[];
}

type AppContextProviderProps = {
  authUserData: AuthUserData;
  children: ReactNode;
};

const AppContext = createContext<AppContextProps>({} as AppContextProps);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider: FC<AppContextProviderProps> = ({
  authUserData,
  children,
}) => {
  const { databaseUserData, updateLastUsedRole } =
    useDatabaseUserData(authUserData);

  const { dataState: systemConfigState } = useSystemConfig();
  const { status: systemConfigStatus, data: systemConfigData } =
    systemConfigState;

  const patientsSearch = usePatientSearch();

  const { centers } = useUserCenters({
    centerIds: databaseUserData?.centerIds,
  });

  if (!databaseUserData || systemConfigStatus !== "success") {
    return <LoadingScreen />;
  }

  return (
    <AppContext.Provider
      value={{
        authUserData,
        databaseUserData,
        updateLastUsedRole,
        systemConfig: systemConfigData,
        patientsSearch,
        centers,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
