import { useCallback, useEffect, useMemo } from "react";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import useDataState from "./useDataState";
import { createSupabaseClient } from "./useSupabase";

export type SystemConfig = DatabaseRow<"system_configs">;

const useSystemConfig = () => {
  const supabase = useMemo(() => createSupabaseClient(), []);

  const [dataState, updateDataState] = useDataState<SystemConfig>();

  const updateSystemConfig = useCallback(async () => {
    try {
      updateDataState({ status: "loading" });

      const { error, data } = await supabase
        .from("system_configs")
        .select()
        .single();

      if (error) throw new Error(error.message);

      updateDataState({ status: "success", data });
    } catch (err) {
      updateDataState({ status: "error", error: (err as Error).message });
    }
  }, [supabase, updateDataState]);

  useEffect(() => {
    updateSystemConfig();
  }, [updateSystemConfig]);

  return { dataState, updateSystemConfig };
};

export default useSystemConfig;
