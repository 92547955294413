import React, { FC, useMemo } from "react";

import useElementSize from "../../../common/useElementSize";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";
import FormControl from "../../Unknown/FormControl";
import InputLabel from "../../Unknown/InputLabel";
import Select from "../../Unknown/Select";
import Typography from "../../Unknown/Typography";
import ListSubheader from "../../Unknown/ListSubHeader";

import CenterServiceMenuItem from "./menuItem";
import useStyles from "./useStyles";

export type Option = {
  groupId: string;
  value: string;
  label: string;
  price: string;
  description: string;
  discountPrice?: string;
  isDisabled?: boolean;
  addOnIds?: string[];
  metadata?: Record<string, string | boolean | number | null>;
};

export type Groups = Record<string, string>;

interface SelectCenterServiceInputProps {
  inputLabel: string;
  options: Option[];
  groups: Groups;
  onChange: (value: Option) => void;
  isDiscountShown?: boolean;
  selectedOption?: Option | null;
  isLoading?: boolean;
  isDisabled?: boolean;
}

type GroupedOption = {
  title: string;
  items: Option[];
};

const SelectCenterServiceInput: FC<SelectCenterServiceInputProps> = ({
  inputLabel,
  options,
  groups,
  isDiscountShown,
  selectedOption,
  onChange,
  isLoading,
  isDisabled,
}) => {
  const { paperRoot, groupRoot } = useStyles();
  const [selectRef, size] = useElementSize();

  const groupedOptions = useMemo(() => {
    return Object.keys(groups).reduce<GroupedOption[]>((acc, groupId) => {
      const items = options
        .filter((option) => option.groupId === groupId)
        .sort((a, b) => a.label.localeCompare(b.label));

      const optionGroup = { title: groups[groupId], items };

      return [...acc, optionGroup];
    }, []);
  }, [groups, options]);

  return (
    <FormControl fullWidth required>
      <InputLabel htmlFor="center-service-select">{inputLabel}</InputLabel>
      <Select
        ref={selectRef}
        fullWidth
        id="center-service-select"
        label={inputLabel}
        value={selectedOption || ""}
        disabled={isDisabled}
        MenuProps={{
          classes: { paper: paperRoot },
          sx: { width: size.width, minWidth: size.width },
        }}
        endAdornment={
          isLoading ? <CircularProgress color="inherit" size={25} /> : null
        }
        renderValue={(value) => {
          if (!value) return null;
          const { label, description } = value;
          return (
            <Box>
              <Typography
                variant="body1"
                whiteSpace="normal"
                sx={{ wordBreak: "break-word" }}
              >
                {label}
              </Typography>
              {description && (
                <Typography variant="caption" whiteSpace="normal">
                  {description}
                </Typography>
              )}
            </Box>
          );
        }}
        required
      >
        {groupedOptions.map((groupedOption) => {
          if (!groupedOption.items.length) return null;

          return (
            <Box key={groupedOption.title}>
              <ListSubheader className={groupRoot}>
                <Box py={2}>
                  <Typography
                    variant="h3"
                    color="common.black"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {groupedOption.title}
                  </Typography>
                </Box>
              </ListSubheader>
              {groupedOption.items.map((item) => (
                <CenterServiceMenuItem
                  key={item.value}
                  {...item}
                  onClick={() => onChange(item)}
                  isDiscountShown={isDiscountShown}
                />
              ))}
            </Box>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectCenterServiceInput;
