import React, { FC, useState } from "react";
import useStyles from "./useStyles";
import PatientInformationDrawerContainer from "../../Patient/PatientInformationDrawerContainer";
import usePatientDrawerContainer from "./usePatientDrawerContainer";
import CustomerInformationDrawerContainer from "../../Customer/CustomerInformationDrawerContainer";
import useCustomerDrawerContainer from "./useCustomerDrawerContainer";
import { useAppContext } from "../../Unknown/AppContext";
import Box from "../../Unknown/Box";
import AppointmentOfferingRoleTable from "../AppointmentOfferingRoleTable";
import { useTableAppointmentOfferings } from "./useTableAppointmentOfferings";

type AppointmentOfferingsTableProps = {
  role?: string;
  startDate: Date;
  endDate: Date;
};

const AppointmentOfferingTableContainer: FC<AppointmentOfferingsTableProps> = ({
  role,
}) => {
  const [isReadShown, setIsReadShown] = useState(false);

  const { tableRoot } = useStyles();

  const {
    databaseUserData: { centerIds },
  } = useAppContext();

  const { appointmentOfferings, isLoading, refetch } =
    useTableAppointmentOfferings({
      centerIds,
      isReadShown,
    });

  const customerDrawerContainerProps = useCustomerDrawerContainer();

  const { isOpen: isPatientDrawerOpen, props: patientDrawerContainerProps } =
    usePatientDrawerContainer({
      refetchAppointmentOffering: refetch,
    });

  const showReadProps = {
    checked: isReadShown,
    onChange: (event: React.ChangeEvent, checked: boolean) =>
      setIsReadShown(checked),
  };

  return (
    <>
      <Box className={tableRoot}>
        <AppointmentOfferingRoleTable
          role={role}
          appointmentOfferings={appointmentOfferings}
          isLoading={isLoading && !appointmentOfferings?.length}
          showReadProps={showReadProps}
        />
      </Box>
      {isPatientDrawerOpen && (
        <PatientInformationDrawerContainer {...patientDrawerContainerProps} />
      )}
      <CustomerInformationDrawerContainer {...customerDrawerContainerProps} />
    </>
  );
};

export default AppointmentOfferingTableContainer;
