import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { useDebounce } from "react-use";
import { createSupabaseClient } from "../../../hooks/useSupabase";
import { useUIContext } from "../UIContext";
import convertSearchValueToRequestParams from "./convertSearchValueToRequestParams";
import getSearchPatients, { SearchPatient } from "./getSearchPatient";
import useTranslations from "./useTranslations";

const MINIMUM_SEARCH_VALUE_LEN = 3;
const SEARCH_DELAY_MS = 500;

export type PatientsSearch = {
  isLoading: boolean;
  patients: SearchPatient[];
  searchValue: string;
  setSearchValue: (value: string) => void;
};

const usePatientSearch = (): PatientsSearch => {
  const [searchParams] = useSearchParams();

  const [patients, setPatients] = useState<SearchPatient[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const { setAlert } = useUIContext();
  const { defaultError } = useTranslations();

  const supabase = useMemo(() => createSupabaseClient(), []);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const params = useParams();
  const { patientMrn } = params;

  const searchQueryValue = searchParams.get("query") || "";

  const [searchValue, setSearchValue] = useState(searchQueryValue);

  const { role, isSearchPage } = useMemo(() => {
    const [rolePath, mainPageName] = pathname.split("/").filter(Boolean);

    return { role: rolePath, isSearchPage: mainPageName === "search" };
  }, [pathname]);

  useEffect(() => {
    if (!isSearchPage && searchValue) {
      navigate(`/${role}/search?query=${encodeURIComponent(searchValue)}`);
    }

    if (isSearchPage) {
      navigate(`${pathname}?query=${encodeURIComponent(searchValue)}`);
    }
  }, [isSearchPage, pathname, patientMrn, role, searchValue, navigate]);

  const updatePatients = useCallback(async () => {
    try {
      if (searchQueryValue.length < MINIMUM_SEARCH_VALUE_LEN) {
        setPatients([]);
        return;
      }

      const requestParams = convertSearchValueToRequestParams(searchQueryValue);

      if (!Object.keys(requestParams).length) {
        setPatients([]);
        return;
      }

      setIsLoading(true);

      const updatedPatients = await getSearchPatients(supabase, requestParams);

      setPatients(updatedPatients);
    } catch (error) {
      setAlert({ isShown: true, severity: "error", message: defaultError });
    } finally {
      setIsLoading(false);
    }
  }, [defaultError, searchQueryValue, setAlert, supabase]);

  useDebounce(updatePatients, SEARCH_DELAY_MS, [updatePatients]);

  return { isLoading, patients, searchValue, setSearchValue };
};

export default usePatientSearch;
