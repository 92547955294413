import { createSupabaseClient } from "../../../hooks/useSupabase";
import { Customer } from "./types";

const supabaseCustomerRequest = `
id,
first_name,
last_name,
email,
mobile_phone_number,
patients (
  mrn,
  first_name,
  last_name
),
customer_audit_logs!customer_audit_logs_customer_id_fkey (
  id,
  created_at,
  fields,
  role,
  customer_id,
  customer: customer_id (
    first_name,
    last_name
  ),
  center_user: center_user_id (
    first_name,
    last_name
  )
),
appointment_customer_details (
  appointment:appointment_id (
    id,
    center:center_id (
      timezone
    )
  )
)
`;

const getCustomerData = async (id: string) => {
  const supabaseClient = createSupabaseClient();

  const { data, error } = await supabaseClient
    .from("customers")
    .select(supabaseCustomerRequest)
    .eq("id", id)
    .limit(1)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .order("created_at" as any, {
      foreignTable: "customer_audit_logs",
      ascending: false,
    })
    .maybeSingle<Customer>();

  if (error) throw new Error(error.message);

  return data;
};

export default getCustomerData;
