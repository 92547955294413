import { defineMessages } from "react-intl";

const messages = defineMessages({
  columnStatus: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.columnStatus",
    defaultMessage: "Status",
  },
  columnAlert: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.columnAlert",
    defaultMessage: "Flags",
  },
  columnNotes: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.columnNotes",
    defaultMessage: "Notes",
  },
  columnOfferingsStatus: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.columnOfferingsStatus",
    defaultMessage: "Status",
  },
  columnPatient: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.columnPatient",
    defaultMessage: "Patient",
  },
  columnDateTime: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.columnDateTime",
    defaultMessage: "Date",
  },
  columnLocation: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.columnLocation",
    defaultMessage: "Location",
  },
  columnOffering: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.columnOffering",
    defaultMessage: "Study",
  },
  columnOfferingStudies: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.columnOfferingStudies",
    defaultMessage: "Studies",
  },

  statusUnread: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.statusUnread",
    defaultMessage: "Unread",
  },
  statusPending: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.statusPending",
    defaultMessage: "Pending",
  },
  statusReading: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.statusReading",
    defaultMessage: "Reading",
  },
  statusRead: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.statusRead",
    defaultMessage: "Read",
  },

  alertTimer: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.alertTimer",
    defaultMessage: "Appointment has STAT addon",
  },

  optionsBtnRead: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.optionsBtnRead",
    defaultMessage: "Read",
  },
  optionsBtnEdit: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.optionsBtnEdit",
    defaultMessage: "Edit",
  },

  btnView: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.btnView",
    defaultMessage: "View appointment",
  },

  tooltipStat: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.tooltipStat",
    defaultMessage: "Appointment has STAT addon",
  },
  tooltipAdditionalViews: {
    id: "Appointment.AppointmentsTableGrid.tooltipAdditionalViews",
    defaultMessage: "Additional views",
  },
  tooltipHasPriority: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.tooltipHasPriority",
    defaultMessage: "Has Priority",
  },
  tooltipUnexpectedFindings: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.tooltipUnexpectedFindings",
    defaultMessage: "Unexpected findings",
  },
  tooltipReceiptConfirmed: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.tooltipReceiptConfirmed",
    defaultMessage: "Receipt confirmed",
  },
  tooltipContactingPatientRequired: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.tooltipContactingPatientRequired",
    defaultMessage: "Contact Patient",
  },
  sideRight: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.sideRight",
    defaultMessage: "Right",
  },
  sideLeft: {
    id: "AppointmentOffering.AppointmentOfferingsTableGrid.sideLeft",
    defaultMessage: "Left",
  },
});

export default messages;
