import intervalToDuration from "date-fns/intervalToDuration";
import React, { FC } from "react";
import Box from "../../Unknown/Box";
import Link from "../../Unknown/Link";
import Typography from "../../Unknown/Typography";
import { formatInternational } from "../../../common/phoneNumberHelpers";
import useTranslations from "./useTranslations";
import useStyles from "./useStyles";

type PatientPreviewProps = {
  fullName: string;
  dateOfBirth: Date | null;
  phoneNumber: string;
  email?: string;
  address?: string;
  locale?: string;
  onNameClick?: () => void;
};

const PatientPreview: FC<PatientPreviewProps> = ({
  fullName,
  dateOfBirth,
  phoneNumber,
  email,
  address,
  locale,
  onNameClick,
}) => {
  const translations = useTranslations();
  const { nameText, linkTypographyRoot } = useStyles();
  const end = new Date();
  const interval = dateOfBirth
    ? intervalToDuration({ start: dateOfBirth, end })
    : "";

  const firstLineText = interval
    ? `${fullName} (${interval.years} ${translations.years})`
    : "";
  const formattedPhoneNumber = formatInternational({
    phoneNumber,
  });

  return (
    <Box overflow="hidden" textOverflow="ellipsis">
      <Typography
        variant="body2"
        className={nameText}
        title={firstLineText}
        onClick={onNameClick}
        sx={{ cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis" }}
      >
        {firstLineText}
      </Typography>
      {dateOfBirth && (
        <Typography variant="body2">
          {dateOfBirth.toLocaleDateString(locale)}
        </Typography>
      )}
      <Typography
        component={Link}
        href={`tel:${phoneNumber}`}
        variant="body2"
        className={linkTypographyRoot}
        display="block"
      >
        {formattedPhoneNumber}
      </Typography>
      {email && (
        <Typography
          variant="body2"
          href={`mailto:${email}`}
          component={Link}
          className={linkTypographyRoot}
          overflow="hidden"
          textOverflow="ellipsis"
          title={email}
          display="block"
        >
          {email}
        </Typography>
      )}
      {address && (
        <Typography
          variant="body2"
          overflow="hidden"
          textOverflow="ellipsis"
          title={address}
        >
          {address}
        </Typography>
      )}
    </Box>
  );
};

export default PatientPreview;
