import { useMemo } from "react";
import { MAX_STRING_LEN } from "../../../common/constants";
import getFormSchema from "./getFormSchema";
import {
  CenterAddOnFormHookParams,
  CenterAddOnFormHookResult,
  CenterAddOnFormikConfig,
} from "./types";
import { MAX_PRICE, TYPE_STAT } from "./constants";
import useLabelTranslations from "./useLabelTranslations";

const useCenterAddOnForm = (
  params: CenterAddOnFormHookParams,
): CenterAddOnFormHookResult => {
  const { centerAddOnData, handleSubmit } = params;
  const labelTranslations = useLabelTranslations();

  const initialValues = useMemo<
    CenterAddOnFormikConfig["initialValues"]
  >(() => {
    const { name, price, type, appliesTo, isContactingPatientRequired } =
      centerAddOnData;

    return {
      name,
      price,
      isStat: type === TYPE_STAT,
      appliesTo,
      isContactingPatientRequired,
    };
  }, [centerAddOnData]);

  const validationSchema = useMemo(() => {
    return getFormSchema({
      labelTranslations,
      maxStringLength: MAX_STRING_LEN,
      maxPrice: MAX_PRICE,
    });
  }, [labelTranslations]);

  return {
    formikConfig: {
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit: handleSubmit,
    },
  };
};

export default useCenterAddOnForm;
