import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { useParams } from "react-router-dom";
import { isBefore } from "date-fns";
import formatISO from "date-fns/formatISO";
import React, { FC, useCallback, useMemo, useState } from "react";
import {
  getNowUTC,
  getTimezoneOffsetMillis,
  UTCDateTimeToTimezone,
} from "../../../common/dateHelpers";
import { createSupabaseClient } from "../../../hooks/useSupabase";
import { useAppContext } from "../../Unknown/AppContext";
import Box from "../../Unknown/Box";
import { EventClickArg, EventSourceFunc } from "../../Unknown/Calendar";
import { useUIContext } from "../../Unknown/UIContext";
import TimeSlotAddDialog from "../TimeSlotAddDialog";
import TimeSlotCalendarGrid from "../TimeSlotCalendarGrid";
import { DateViewChangeParams, View } from "../TimeSlotCalendarGrid/helpers";
import TimeSlotCalendarHeader from "../TimeSlotCalendarHeader";
import TimeSlotDeletionConfirmationDialog from "../TimeSlotDeletionConfirmationDialog";
import convertTimeSlotsToEvents, {
  CalendarEventExtendedProp,
} from "./convertTimeSlotsToEvents";
import getCalendarTimeSlots from "./getCalendarTimeSlots";
import useCalendarApi from "./useCalendarApi";
import useSearchCalendarParams from "./useSearchCalendarParams";
import useStyles from "./useStyles";
import useTimeSlotAddDialog from "./useTimeSlotAddDialog";
import useTimeSlotDeleteDialog from "./useTimeSlotDeleteDialog";
import useTimeSlotEditDialog from "./useTimeSlotEditDialog";
import useTranslations from "./useTranslations";

const TimeSlotCalendarScreen: FC = () => {
  const supabase = useMemo(() => createSupabaseClient(), []);

  const { calendarRoot } = useStyles();
  const { translations } = useTranslations();
  const { role } = useParams<{ role: DatabaseEnum["center_user_role"] }>();
  const { setAlert } = useUIContext();

  const { initialCalendarSearchParams, setSearchCalendarParams } =
    useSearchCalendarParams();

  const [start, setStart] = useState<Date>(new Date());
  const [end, setEnd] = useState<Date>(new Date());
  const [selectedView, setSelectedView] = useState<View>("week");

  const { databaseUserData, centers } = useAppContext();
  const { centerIds } = databaseUserData;

  const events = useCallback<EventSourceFunc>(
    async (fetchInfo) => {
      try {
        const { start: startDate, end: endDate } = fetchInfo;
        const timeSlots = await getCalendarTimeSlots({
          supabase,
          start: startDate,
          end: endDate,
          centerIds,
        });

        const {
          left,
          right,
          patientDetailsIsNotAvailable,
          errorAppointmentUndefined,
          errorPatientDetailsUndefined,
          defaultError,
        } = translations;

        return convertTimeSlotsToEvents({
          role,
          timeSlots,
          translations: {
            left,
            right,
            errorAppointmentUndefined,
            errorPatientDetailsUndefined,
            patientDetailsIsNotAvailable,
            defaultError,
          },
        });
      } catch (error) {
        setAlert({
          isShown: true,
          severity: "error",
          message: translations.defaultError,
        });
        return [];
      }
    },
    [centerIds, role, setAlert, supabase, translations],
  );

  const onDateViewChange = useCallback(
    (params: DateViewChangeParams) => {
      const { start: startDate, end: endDate, view } = params;

      setSelectedView(view);
      setStart(startDate);
      setEnd(endDate);

      setSearchCalendarParams({
        view,
        date: formatISO(startDate, { representation: "date" }),
      });
    },
    [setSearchCalendarParams],
  );

  const {
    calendarRef,
    onNextClick,
    onPrevClick,
    onViewChange,
    onAddEvent,
    onEditEvent,
    onDeleteEvent,
  } = useCalendarApi();

  const centerData = useMemo(() => {
    const primaryCenter = centers.find(
      (center) => center.id === databaseUserData.primaryRole.center_id,
    );

    return primaryCenter;
  }, [centers, databaseUserData.primaryRole.center_id]);

  const { onOpen: onTimeSlotAddDialogOpen, ...timeSlotAddDialogProps } =
    useTimeSlotAddDialog({ onTimeSlotAdd: onAddEvent, centerData });

  const {
    onOpen: onDeleteDialogOpen,
    ...timeSlotDeletionConfirmationDialogProps
  } = useTimeSlotDeleteDialog({ onTimeSlotDelete: onDeleteEvent });

  const { onOpen: onEditDialogOpen, ...timeSlotEditDialogProps } =
    useTimeSlotEditDialog({
      onTimeSlotEdit: onEditEvent,
      onDeleteDialogOpen,
      centerData,
    });

  const onEventClick = useCallback(
    (arg: EventClickArg) => {
      const { title, start: eventStart, end: eventEnd } = arg.event;
      const extendedProps = arg.event
        .extendedProps as CalendarEventExtendedProp;

      if (
        extendedProps.timeSlotType !== "planned_closures" ||
        !eventStart ||
        !eventEnd
      ) {
        return;
      }

      const timezoneOffsetMillis = getTimezoneOffsetMillis(
        centerData?.timezone,
      );

      const nowTimezone = UTCDateTimeToTimezone(
        getNowUTC(),
        timezoneOffsetMillis,
      );

      const isEventInPast = isBefore(eventStart, nowTimezone);

      if (isEventInPast) return;

      onEditDialogOpen({
        timeSlotId: extendedProps.timeSlotId,
        name: title,
        start: eventStart,
        end: eventEnd,
      });
    },
    [centerData?.timezone, onEditDialogOpen],
  );

  return (
    <>
      <Box pb={3}>
        <TimeSlotCalendarHeader
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
          onViewChange={onViewChange}
          onAddBlockClick={onTimeSlotAddDialogOpen}
          start={start}
          end={end}
          selectedView={selectedView}
        />
      </Box>
      <Box className={calendarRoot}>
        <TimeSlotCalendarGrid
          events={events}
          calendarRef={calendarRef}
          onDateViewChange={onDateViewChange}
          initialDate={initialCalendarSearchParams.initialDate}
          initialView={initialCalendarSearchParams.initialView}
          eventClick={onEventClick}
        />
      </Box>
      <TimeSlotAddDialog {...timeSlotAddDialogProps} />
      <TimeSlotAddDialog {...timeSlotEditDialogProps} />
      <TimeSlotDeletionConfirmationDialog
        {...timeSlotDeletionConfirmationDialogProps}
      />
    </>
  );
};

export default TimeSlotCalendarScreen;
