import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { useEffect, useCallback, useState, useMemo } from "react";
import { createSupabaseClient } from "../../../hooks/useSupabase";
import { useUIContext } from "../UIContext";
import useTranslations from "./useTranslations";

export type UserCenter = Pick<
  DatabaseRow<"centers">,
  "name" | "id" | "timezone"
>;

const supabaseCentersRequest = `
id,
name,
timezone
`;

const useUserCenters = (params: { centerIds?: string[] }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [centers, setCenters] = useState<UserCenter[]>([]);
  const { setAlert } = useUIContext();
  const { defaultError } = useTranslations();
  const supabase = useMemo(() => createSupabaseClient(), []);

  const { centerIds } = params;

  const updateCenters = useCallback(async () => {
    if (!centerIds) return;

    try {
      setIsLoading(true);

      const { data, error } = await supabase
        .from("centers")
        .select(supabaseCentersRequest)
        .in("id", centerIds);

      if (error) throw new Error(error.message);

      setCenters(data);
    } catch (error) {
      setAlert({ isShown: true, severity: "error", message: defaultError });
    } finally {
      setIsLoading(false);
    }
  }, [centerIds, defaultError, setAlert, supabase]);

  useEffect(() => {
    updateCenters();
  }, [updateCenters]);

  return { isLoading, centers };
};

export default useUserCenters;
