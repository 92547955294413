import { useEffect, useState, useCallback, useMemo } from "react";
import { createSupabaseClient } from "../../../hooks/useSupabase";
import useTranslations from "../../Unknown/AppContext/useTranslations";
import { useUIContext } from "../../Unknown/UIContext";
import getAppointmentOfferings, {
  DashboardAppointmentOffering,
} from "./getAppointmentOfferings";
import useSystemConfig from "../../../hooks/useSystemConfig";
import supabaseClient from "../../../common/supabaseClient";
import { useAppContext } from "../../Unknown/AppContext";

export const useTableAppointmentOfferings = (params: {
  centerIds: string[];
  isReadShown: boolean;
}) => {
  const { centerIds, isReadShown } = params;
  const supabase = useMemo(() => createSupabaseClient(), []);
  const translations = useTranslations();
  const {
    dataState: { data: config, status: configStatus },
  } = useSystemConfig();

  const { setAlert } = useUIContext();

  const { centers } = useAppContext();

  const timezone = centers.length === 1 ? centers[0].timezone : null;

  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(false);
  const [appointmentOfferings, setAppointmentOfferings] = useState<
    DashboardAppointmentOffering[]
  >([]);

  const fetch = useCallback(async () => {
    try {
      if (!supabase) return;

      if (!appointmentOfferings.length) {
        setIsAppointmentsLoading(true);
      }

      const unreadOfferingsData = await getAppointmentOfferings({
        supabase,
        centerIds,
        isReadShown: false,
        showReadStudiesDays: config?.show_read_studies_duration_days || 0,
        timezone,
      });

      const readOfferingsData = isReadShown
        ? await getAppointmentOfferings({
            supabase,
            centerIds,
            isReadShown: true,
            showReadStudiesDays: config?.show_read_studies_duration_days || 0,
          })
        : [];

      setAppointmentOfferings([...unreadOfferingsData, ...readOfferingsData]);
    } catch (error) {
      setAlert({
        isShown: true,
        message: translations.defaultError,
        severity: "error",
      });

      setAppointmentOfferings([]);
    } finally {
      setIsAppointmentsLoading(false);
    }
  }, [
    appointmentOfferings.length,
    supabase,
    centerIds,
    config?.show_read_studies_duration_days,
    timezone,
    isReadShown,
    setAlert,
    translations.defaultError,
  ]);

  useEffect(() => {
    const getOfferings = async () => {
      await fetch();
    };

    if (configStatus === "success" && config?.show_read_studies_duration_days) {
      getOfferings();
    }

    const appointmentOfferingSubscription = supabaseClient
      .channel("appointment-offerings-update")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "appointment_offerings" },
        (payload) => {
          if (
            payload.new &&
            configStatus === "success" &&
            config?.show_read_studies_duration_days
          ) {
            getOfferings();
          }
        },
      )
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "appointments" },
        (payload) => {
          if (
            payload.new &&
            payload.new.status !== payload.old.status &&
            configStatus === "success" &&
            config?.show_read_studies_duration_days
          ) {
            getOfferings();
          }
        },
      )
      .subscribe();

    return () => {
      supabaseClient.removeChannel(appointmentOfferingSubscription);
    };
  }, [
    fetch,
    isReadShown,
    configStatus,
    config?.show_read_studies_duration_days,
  ]);

  return {
    appointmentOfferings,
    isLoading: isAppointmentsLoading,
    refetch: fetch,
  };
};
