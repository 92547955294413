import * as Yup from "yup";
import {
  CenterAddOnFormValues,
  LabelTranslationsKey,
} from "../CenterAddOnForm/types";

const getCenterAddOnFormSchema = (params: {
  labelTranslations: Record<LabelTranslationsKey, string>;
  maxStringLength: number;
  maxPrice: number;
}) => {
  const { labelTranslations, maxStringLength, maxPrice } = params;

  const requiredString = Yup.string().trim().max(maxStringLength).required();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Yup.object().shape<Record<keyof CenterAddOnFormValues, any>>({
    name: requiredString.label(labelTranslations.labelName),
    price: Yup.number()
      .label(labelTranslations.labelPrice)
      .positive()
      .max(maxPrice)
      .required(),
    isStat: Yup.boolean().label(labelTranslations.labelStat).nullable(),
    isContactingPatientRequired: Yup.boolean().nullable(),
    appliesTo: Yup.mixed()
      .oneOf(["appointment", "study"])
      .label(labelTranslations.labelAppliesTo),
  });
};

export default getCenterAddOnFormSchema;
