import { SystemConfig } from "../../../hooks/useSystemConfig";
import { SettingsCenter } from "./getSettingsCenterData";
import { AddOnAppliesTo, AddOnsData } from "./types";

const normalizeAddOns = (
  center: SettingsCenter,
  systemConfig: SystemConfig,
): AddOnsData => {
  const centerConfig = center.center_configs[0];

  const currencyCode =
    centerConfig?.default_currency_code || systemConfig.default_currency_code;

  const addOns = center.center_add_ons.map((addOn) => {
    const {
      id,
      name,
      price,
      type,
      applies_to,
      is_contacting_patient_required,
    } = addOn;

    const appliesTo = (applies_to || "appointment") as AddOnAppliesTo;

    return {
      id,
      name,
      price,
      type,
      appliesTo,
      isContactingPatientRequired: !!is_contacting_patient_required,
    };
  });

  return { addOns, currencyCode };
};

export default normalizeAddOns;
