import Big from "big.js";
import { CheckboxGroupOption } from "../../Unknown/CheckboxGroup";

type AddOn = {
  id: string;
  name: string;
  price: number;
};

const parseAddOn = ({
  addOn,
  currencyCode,
  checked,
  offeringsCount = 1,
}: {
  addOn: AddOn;
  currencyCode: string;
  checked: boolean;
  offeringsCount?: number;
}): CheckboxGroupOption => {
  const { id, name, price } = addOn;

  const totalPrice = Big(price).times(offeringsCount).toNumber();

  return {
    value: id,
    label: name,
    price: totalPrice,
    currencyCode,
    checked,
  };
};

export default parseAddOn;
