import Big from "big.js";
import { AddOn } from "./types";

export type AddOnSummaryPosition = {
  name: string;
  price: number;
};

export type CenterServiceSummryPosition = {
  name: string;
  price: number;
  studyIds: string[];
  addOnIds: string[];
};

const getTotalSummary = (params: {
  centerServices: CenterServiceSummryPosition[];
  discountAmount?: number;
  addOns: AddOnSummaryPosition[];
  centerAddOns: AddOn[];
}) => {
  const { centerServices, discountAmount, addOns, centerAddOns } = params;

  const centerServicesPriceBig = centerServices.reduce((sum, centerService) => {
    const studiesCount = centerService.studyIds.length;
    const centerServiceAddonsPrices = centerService.addOnIds.reduce(
      (addOnSum, addOnId) => {
        const addOnPrice =
          centerAddOns.find((centerAddOn) => centerAddOn.id === addOnId)
            ?.price || 0;

        const addOnPriceBig = Big(addOnPrice).times(studiesCount);
        return addOnSum.plus(addOnPriceBig);
      },
      Big(0),
    );

    return sum.plus(centerService.price).plus(centerServiceAddonsPrices);
  }, Big(0));

  const addOnsPriceBig = addOns.reduce(
    (sum, addOn) => sum.plus(addOn.price),
    Big(0),
  );

  const price = centerServicesPriceBig.plus(addOnsPriceBig).round(2).toNumber();

  const additionalServicesLength = Math.max(centerServices.length - 1, 0);

  const discountSum = discountAmount
    ? Big(discountAmount).times(additionalServicesLength).toNumber()
    : 0;

  const priceWithDiscount = Big(price).minus(discountSum).toNumber();

  return {
    price,
    discountSum,
    priceWithDiscount,
  };
};

export default getTotalSummary;
