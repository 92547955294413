import React, { FC } from "react";
import { useIntl } from "react-intl";

import commonMessages from "../../../common/messages";
import Box from "../../Unknown/Box";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";

import useStyles from "./useStyles";
import { AddOnsState, CenterConfig, SelectedCenterService } from "./types";
import { SummaryPositionItem } from "./summary-item";

export interface AppointmentSummaryDetailsProps {
  centerConfig?: CenterConfig;
  selectedCenterServices?: (SelectedCenterService | null)[];
  selectedAddOns?: AddOnsState;
  discountSum: number;
  priceWithDiscount: number;
  centerAddOns: AddOnsState;
}

const AppointmentSummaryDetails: FC<AppointmentSummaryDetailsProps> = ({
  centerConfig,
  selectedCenterServices = [],
  selectedAddOns = [],
  discountSum,
  priceWithDiscount,
  centerAddOns,
}) => {
  const { formatMessage, formatNumber } = useIntl();
  const classes = useStyles();

  const { defaultCurrencyCode } = centerConfig ?? {};

  const totalPriceWithDiscountText = formatNumber(priceWithDiscount, {
    style: "currency",
    currency: defaultCurrencyCode,
  });

  const isDiscountShown = discountSum !== 0;

  const sideTranslations: Record<string, string> = {
    left: formatMessage(commonMessages.left),
    right: formatMessage(commonMessages.right),
  };

  const centerServicesPositions = selectedCenterServices.map((service) => ({
    name: service?.name,
    side: (service?.side as string) || "",
    price: service?.price,
    addOnIds: service?.addOnIds,
  }));

  const addOnsPositions = selectedAddOns.map((addOn) => ({
    name: addOn.name,
    price: addOn.price
      ? formatNumber(addOn.price, {
          style: "currency",
          currency: defaultCurrencyCode,
        })
      : "",
    side: undefined,
  }));

  const summaryPositions = [...centerServicesPositions, ...addOnsPositions];

  const isSummaryPositionsShown = !!summaryPositions.length;

  return (
    <Box className={classes.summaryRoot} position="sticky" top={15}>
      <Grid container spacing={5} direction="column">
        {isSummaryPositionsShown && (
          <Grid item>
            {centerServicesPositions.map((service) => {
              if (!service) return null;

              const {
                name: summaryPositionName,
                side,
                price,
                addOnIds,
              } = service;

              const sideTranslation = side ? sideTranslations[side] : "";

              const serviceName = sideTranslation
                ? `${summaryPositionName} (${sideTranslation})`
                : summaryPositionName;

              const servicePrice = price
                ? formatNumber(price, {
                    style: "currency",
                    currency: defaultCurrencyCode,
                  })
                : "";

              return (
                <>
                  <SummaryPositionItem
                    key={serviceName}
                    name={serviceName || ""}
                    price={servicePrice}
                  />
                  {addOnIds?.map((serviceAddOnId) => {
                    const serviceAddOn = centerAddOns.find(
                      (addOn) => addOn.id === serviceAddOnId,
                    );

                    if (!serviceAddOn) return null;

                    const serviceAddOnName = `${serviceAddOn.name} for ${serviceName}`;
                    const serviceAddOnPrice = formatNumber(serviceAddOn.price, {
                      style: "currency",
                      currency: defaultCurrencyCode,
                    });

                    return (
                      <SummaryPositionItem
                        key={serviceAddOnName}
                        name={serviceAddOnName}
                        price={serviceAddOnPrice}
                      />
                    );
                  })}
                </>
              );
            })}
            {addOnsPositions.map((summaryPosition) => {
              const { name: addOnName, price } = summaryPosition;
              return (
                <SummaryPositionItem
                  key={addOnName}
                  name={addOnName}
                  price={price}
                />
              );
            })}
          </Grid>
        )}
        {isDiscountShown && (
          <Grid item>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography>{formatMessage(commonMessages.discount)}</Typography>
              <Typography>
                -
                {formatNumber(discountSum, {
                  style: "currency",
                  currency: defaultCurrencyCode,
                })}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight={700}>
              {formatMessage(commonMessages.total)}
            </Typography>
            <Typography fontWeight={700}>
              {totalPriceWithDiscountText}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppointmentSummaryDetails;
